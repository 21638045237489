import { render, staticRenderFns } from "./Boleto.vue?vue&type=template&id=245f0ace&scoped=true&"
import script from "./Boleto.vue?vue&type=script&lang=ts&"
export * from "./Boleto.vue?vue&type=script&lang=ts&"
import style0 from "./Boleto.vue?vue&type=style&index=0&id=245f0ace&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "245f0ace",
  null
  
)

export default component.exports