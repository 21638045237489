import Vue from 'vue'
import RelatorioPage from './shared/RelatorioPage.vue';
import MasterPage   from "./shared/MasterPage.vue";
import AlterarSenha from "./shared/AlterarSenha.vue";
import CadastroContrato from "./clientes/contrato/Cadastro.vue";
import MeusDados from "./clientes/pessoa/MeusDados.vue";
import ListaContratos from "./clientes/contrato/ListaContratos.vue";
import ExtratoPagamento from "./clientes/contrato/ExtratoPagamento.vue";

Vue.component("master-page", MasterPage);
Vue.component("alterar-senha", AlterarSenha);
Vue.component("cadastro-contrato", CadastroContrato);
Vue.component("meus-dados", MeusDados);
Vue.component("lista-contratos", ListaContratos);
Vue.component("extrato-pagamento", ExtratoPagamento);
Vue.component('relatorio-page', RelatorioPage);
