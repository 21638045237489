



















































import { PageBase } from '@/core/models/shared';
import { Component, Prop } from "vue-property-decorator";

@Component
export default class RelatorioPage extends PageBase {

    @Prop() private titulo!: string;
    @Prop() private cabecalhoCompleto?: boolean;
    @Prop() private marcaDagua?: string;
    @Prop() private paisagem?: boolean;
    @Prop() private exportarExcel?: boolean;

    //empresaService = new EmpresaService()
    //empresa: Empresa = new Empresa();
    data: any = Date.now;

    mounted() {

        let root = document.documentElement;
        // this.empresaService.ObterPorId(this.app.empresaId, 'Endereco.Municipio, Endereco.Municipio.Estado')
        //     .then(
        //         res=> this.empresa = res.data,
        //         err=> { }
        //     )

        if(this.paisagem){
            root.style.setProperty("--widthPage", 45 + 'cm');  //29.7
            root.style.setProperty("--min-heightPage", 19 + 'cm');
            this.setPageSize('landscape')
        }      
        else{
            root.style.setProperty("--widthPage", 21 + 'cm');
            root.style.setProperty("--min-heightPage", 10 + 'cm');
            this.setPageSize('portrait')
        }
    }

    setPageSize(size: string){
        const style = document.createElement('style');
        style.innerHTML = `@page {size: ${size}}`;
        document.head.appendChild(style);
    }

    Imprimir(){
        window.print();
    }

    Fechar(){
        window.close();
    }
}
