import Vue from 'vue'
import VueRouter, {RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '*',
    redirect: '/login'
  }, 
  {
    path: '/', 
    alias: '/home',
    name: 'home',
    component: () => import("../views/Home.vue"),
    beforeEnter: (to: any, from: any, next: any) => {
      if (localStorage.getItem('sessionApp') != null) {
      let exp = JSON.parse(atob(localStorage.getItem('sessionApp')!.split('.')[3])).exp
       let expDate = new Date(exp * 1000);
       let today = new Date();
       if (today > expDate) {
         localStorage.clear();
         next('/login');
       } else { 
         next();
       }
     } else {
       next('/login');  
     }
    }
 },  
  
  { path: '/', name: 'Home', component: () => import("../views/Home.vue") },
  { path: '/login', name: 'Login', component: () => import("../views/Login.vue") },
  { path: "/login/:token", name: "ConfirmaEmail", component: () => import("../views/Login.vue")},
  { path: "/login/resetSenha/:token", name: "ResetarSenha", component: () => import("../views/ResetarSenha.vue")},
  { path: '/pessoa', name: 'Pessoa', component: () => import("../views/MeusDados.vue") },
  { path: "/ExtratoPagamento", name: "ExtratoPagamento", component: () => import("../views/RelExtratoPagamento.vue")},
  { 
    path: '/RelBoleto',
    name: 'RelBoleto',
    component: () => import('@/views/financeiro/Boleto.vue')
  },

] 

const router = new VueRouter({ 
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
