import { http } from "@/core/ApiConfig";
import { Service } from "../shared";

 
export class BoletoService extends Service {
    constructor(){
        super('boleto');
    }

    VisualizarBoletoSafra(codigo:string){
        return http.post(`${this.GetNomeControle()}/html/${codigo}`)
    }
  
    VisualizarBoleto(transacaoId){
        return http.post(`${this.GetNomeControle()}/visualizar/${transacaoId}`)
    }
} 
   