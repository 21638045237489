











































import { PageBase } from "@/core/models/shared";
import { ContratoService } from "@/core/services/Clientes";
import { RelatorioService } from "@/core/services/Financeiro";
 import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ExtratoPagamento extends PageBase {
   @Prop() private value!: string;

    exportarExcel: boolean = true

    item: any = {};
    service = new ContratoService();
    relatorioService = new RelatorioService();

    lista: any[] = [];

    loading: boolean = false;
    
    valid = true;
    dialog = false;

    filter: {
        contratoId:any;
         dataBaixaInicial: any;
         dataBaixaFinal:any
    } = {
        contratoId: 0,
       
        dataBaixaInicial: new Date().getFullYear(),
        dataBaixaFinal: new Date().getFullYear(),
    }

    ano:string = '';

    Visualizar() {
        if(this.filter.contratoId == 0){
            this.$swal('Aviso', 'É necessário selecionar o Contrato', 'error')
            return;
        }
      
        let routeLink = this.$router.resolve({name: 'ExtratoPagamento',
        query:{
            contratoId: this.filter.contratoId ? this.filter.contratoId : "",
            dataBaixaInicial: this.filter.dataBaixaInicial ? `${this.filter.dataBaixaInicial}-01-01` : "",
            dataBaixaFinal: this.filter.dataBaixaFinal ? `${this.filter.dataBaixaFinal}-12-31` : ""
         }
        }); 
        window.open(routeLink.href, '_blank');
    }
    anos:any = [];

    GetAnos(){
        var max = new Date().getFullYear(); 
        var min = max - 40
 
        for (let i = max; i >= min; i--) {
            this.anos.push(i)
        }
        return this.anos 
    }

    
    Carregar(){
        this.loading = true;
        this.service.ListarPorCliente(this.app.clienteId, '').then(
            res => {
                this.lista = res.data;
            },

            err => {
                if (!err.response) {
                    this.$swal("Aviso", "Não foi possível acessar a API", "error");

                } else {
                    this.$swal("Aviso", err.response.data, "error");
            }
        }
        ).finally(() => {
            this.loading = false;
        })
    }  

    $refs!: {
        form: HTMLFormElement;
    }; 

    @Watch("value")
    Value() {
        this.dialog = this.value ? true : false;
    }

    @Watch("dialog")
    Dialog() {
        if (!this.dialog) {
            this.$emit("fechou");
        }
    }

    @Watch("item") 
    Item() {
        if (this.$refs.form) {
            this.$refs.form.resetValidation();
        } 
    }

    Close(){
        this.dialog = false;
        this.filter = {contratoId: 0, dataBaixaInicial: new Date().getFullYear(), dataBaixaFinal: new Date().getFullYear()}
    }

    mounted(){
        this.Carregar();
        this.GetAnos();
    }
}
